<!--
 * @Author: your name
 * @Date: 2021-08-30 10:35:48
 * @LastEditTime: 2021-09-01 09:49:34
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\views\profileSetting\components\loginHistory.vue
-->
<template>
  <!-- 第二个卡片 -->
  <el-card class="card_right_bottom" v-loading="loading">
    <!-- 标题 -->
    <!-- 登录历史 -->
    <div class="card_right_bottom_title">{{ $t("label.loginhistory") }}</div>
    <!-- 第一个盒子 -->
    <div class="box1">
      <el-table :data="tableData" style="width: 100%" border>
        <!-- 登录时间 -->
        <el-table-column prop="loginTime" :label="$t('label.login.time')" />
        <el-table-column prop="sourceIp" label="IP" />
        <!-- 登录类型 -->
        <el-table-column prop="loginType" :label="$t('label.login.type')" />
        <!-- 状态 -->
        <el-table-column prop="status" :label="$t('label.chatter.state')" />
        <!-- 浏览器 -->
        <el-table-column prop="explorer" :label="$t('label.browser')" />
        <!-- 登录系统 -->
        <el-table-column
          prop="operatingSystem"
          :label="$t('label.loginsystem')"
        />
      </el-table>

      <div class="download_file_btn">
        <!-- 下载最近六个月的登录历史.(Excel.csv 文件) -->
        <a :href="url" download="">{{
          $t("message.loginhistory.downloadcsv")
        }}</a>
      </div>
    </div>
  </el-card>
</template>

<script>
import { getLoginHistory } from "@/views/profileSetting/profileSettingApi.js";
export default {
  data() {
    let baseURL = this.$baseConfig.baseURL;
    let token = this.$CCDK.CCToken.getToken();
    let url = `${baseURL}/accessApi/login/downloadLoginHistory?accessToken=${token}`;
    return {
      loading: true,
      url, //  下载最近六个月内容
      // 登录历史数据
      tableData: [],
    };
  },
  created() {
    this.getLoginHistory();
  },
  methods: {
    // 获取用户最近十次的登录历史
    getLoginHistory() {
      getLoginHistory().then((res) => {
        this.loading = false;
        if (res.result) {
          this.tableData = res.data.loginList.map((item) => {
            let loginTimeAry = item.loginTime.split(" ");
            if (loginTimeAry[2] == "PM") {
              //  下午时间
              item.loginTime = `${loginTimeAry[0]} ${this.$i18n.t("label.pm")}${
                loginTimeAry[1]
              }`;
            } else if (loginTimeAry[2] == "AM"){
              // 上午时间
              item.loginTime = `${loginTimeAry[0]} ${this.$i18n.t("label.am")}${
                loginTimeAry[1]
              }`;
            }
            return item;
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style/comment";
</style>